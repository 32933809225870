<div class="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="animate-spin"><path d="M21 12a9 9 0 1 1-6.219-8.56" /></svg
  >
</div>
