import { writable } from "svelte/store";
import * as Sentry from "@sentry/svelte";

export const state = writable({});

export const logout = async (e) => {
  e.target.disabled = true;

  const res = await fetch(import.meta.env.VITE_API_HOSTNAME + "/auth/logout", {
    credentials: "include",
    mode: "cors",
  });

  const json = await res.json();
  state.set(json);
  e.target.disabled = false;
};

export const updateState = async () => {
  const res = await fetch(import.meta.env.VITE_API_HOSTNAME + "/api/state", {
    credentials: "include",
    mode: "cors",
  });

  const resJSON = await res.json();
  state.set(resJSON);

  if (resJSON?.auth.loggedin)
    Sentry.setUser({ email: resJSON.auth.suid + "@sun.ac.za" });
};
