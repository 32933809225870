<script>
  import { state, updateState } from "./lib/State";

  import Loading from "./pages/Loading.svelte";
  import Home from "./pages/Home.svelte";
  import DashboardStudent from "./pages/DashboardStudent.svelte";

  let component = Loading;

  state.subscribe((state) => {
    if (state.auth) {
      if (state.auth.loggedin == true) component = DashboardStudent;
      else component = Home;
    } else {
      component = Loading;
    }
  });

  updateState();
</script>

<svelte:component this={component} />
