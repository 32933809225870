<script>
  import { state } from "../lib/State";

  const saveAssessment = async (e, submissionId) => {
    e.target.setAttribute("data-saving", true);

    const res = await fetch(
      import.meta.env.VITE_API_HOSTNAME + "/api/assessment",
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        redirect: "follow",
        body: JSON.stringify({
          submissionId,
          entrustability: parseInt(e.target.value),
        }),
      }
    );

    e.target.removeAttribute("data-saving");
  };
</script>

{#each $state.peerAssessments as submission}
  <div class="border-b border-muted py-8 px-2 flex justify-between gap-4">
    <div class="flex-grow">
      <h2 class="font-medium leading-snug mb-2">
        {submission.Student.name}
        ({$state.skills.find((i) => i.id == submission.skillId).name})
      </h2>

      <div class="text-muted-foreground text-sm">
        <div>
          {new Date(submission.performedDate).toLocaleDateString()}, {submission.site}
        </div>
      </div>
    </div>

    <select
      class="appearance-none touch-manipulation text-sm px-4 py-3 outline-none bg-muted text-foreground font-medium transition-colors rounded-xl leading-none inline-block self-top data-[saving]:text-stone-500/30"
      value={submission.entrustability || 0}
      on:change={(e) => saveAssessment(e, submission.id)}
    >
      <option value={0} disabled>- / 5</option>
      <option value={1}>1 / 5</option>
      <option value={2}>2 / 5</option>
      <option value={3}>3 / 5</option>
      <option value={4}>4 / 5</option>
      <option value={5}>5 / 5</option>
    </select>
  </div>
{/each}
