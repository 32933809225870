<script>
  import { fade, fly } from "svelte/transition";
  import AssessorSelect from "./AssessorSelect.svelte";

  import { state } from "../lib/State";

  export let open = false;

  const today = (() => {
    const date = new Date();
    const dateAdj = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    return dateAdj.toISOString().split("T")[0];
  })();

  let dateValue, assessorSuid, valueFacility, valueSkillId, formDisabled;

  const openDrawer = () => {
    dateValue = today;
    assessorSuid = "";
    valueFacility = "";
    valueSkillId = "";

    formDisabled = false;
    open = true;
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    formDisabled = true;

    //Submit form
    const body = {
      site: valueFacility,
      skillId: valueSkillId,
      assessorSuid,
      date: dateValue,
    };

    const res = await fetch(
      import.meta.env.VITE_API_HOSTNAME + "/api/submission",
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        redirect: "follow",
        body: JSON.stringify(body),
      }
    );

    const json = await res.json();
    state.set(json);
    formDisabled = false;
    open = false;
  };
</script>

<button on:click={openDrawer} class="contents">
  <slot />
</button>

{#if open}
  <!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions -->
  <div
    id="open-dialog"
    transition:fade={{ duration: 200 }}
    on:click={() => (open = false)}
    class="touch-manipulation fixed w-full h-full left-0 right-0 top-0 bottom-0 bg-gray-600/40 cursor-default backdrop-blur-[1px]"
  />

  <section
    transition:fly={{ duration: 200, y: 100, opacity: 0 }}
    class="bg-background fixed bottom-0 left-0 right-0 w-full rounded-se-3xl rounded-ss-3xl outline outline-1 outline-muted"
  >
    <div class="mx-auto max-w-full w-[560px] p-4 pb-12">
      <div class="flex justify-between mb-8 items-center select-none">
        <h1 class="leading-none font-bold text-xl p-2">Log skill</h1>
        <button
          class="content p-2 hover:bg-muted/50 active:bg-muted rounded-full touch-manipulation"
          on:click={() => (open = false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="block"
            ><line x1="18" y1="6" x2="6" y2="18"></line><line
              x1="6"
              y1="6"
              x2="18"
              y2="18"
            ></line></svg
          >
        </button>
      </div>
      <form class="px-2" on:submit={formSubmit}>
        <fieldset disabled={formDisabled} class="disabled:opacity-50">
          <div class="flex gap-3 mb-4">
            <input
              type="date"
              name="date_performed"
              class="block appearance-none px-4 py-3 outline-none border transition-colors h-11 border-green-500 bg-green-500/10 rounded-md leading-none w-64 invalid:border-red-500 invalid:bg-red-500/10 [&::-webkit-date-and-time-value]:text-left [&::-webkit-datetime-edit]:block [&::-webkit-datetime-edit]:py-0"
              bind:value={dateValue}
              max={today}
              required
              min={new Date(new Date().getFullYear(), 0, 2)
                .toISOString()
                .split("T")[0]}
            />

            <AssessorSelect options={$state.users} bind:assessorSuid />
          </div>
          <select
            name="site"
            class="h-11 appearance-none px-4 py-3 outline-none valid:border-green-500 valid:bg-green-500/10 bg-inherit border transition-colors border-input rounded-md leading-none block w-full mb-3"
            bind:value={valueFacility}
            required
          >
            <option value="" disabled selected>Clinical Site</option>
            {#each $state.facilities as facility}
              <option value={facility}>{facility}</option>
            {/each}
          </select>
          <select
            name="site"
            class="h-11 appearance-none px-4 py-3 outline-none valid:border-green-500 valid:bg-green-500/10 bg-inherit border transition-colors border-input rounded-md leading-none block w-full mb-9"
            bind:value={valueSkillId}
            required
          >
            <option value="" disabled selected>Skill</option>
            {#each $state.skills as skill}
              <option value={skill.id}>{skill.name}</option>
            {/each}
          </select>
          <div class="flex gap-4">
            <button
              on:click={() => (open = false)}
              type="button"
              class="flex w-full items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring touch-manipulation h-11 rounded-md px-8 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground"
              >Cancel</button
            >
            <button
              type="submit"
              class="flex w-full items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring touch-manipulation h-11 px-8 bg-primary text-primary-foreground shadow hover:bg-primary/90"
              >Submit</button
            >
          </div>
        </fieldset>
      </form>
    </div>
  </section>
{/if}

<style>
  :global(body:has(#open-dialog)) {
    overflow: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
</style>
