<script>
  import { state } from "../lib/State.js";
</script>

{#each $state.submissions as submission}
  <div
    class="border p-5 py-4 rounded-xl flex justify-between gap-2 shadow-sm mb-4 border-muted"
  >
    <div class="flex-grow">
      <h2 class="font-medium flex leading-tight mb-2">
        <div
          class="inline-block w-3 h-3 rounded-full m-1 mr-2 ml-0 {submission
            .Assessor.facilitator
            ? 'bg-blue-500'
            : 'bg-lime-500'}"
        />
        {$state.skills.find((i) => i.id == submission.skillId).name}
      </h2>

      <div class="text-muted-foreground text-sm">
        <div>
          {new Date(submission.performedDate).toLocaleDateString()}, {submission.site}
        </div>
        <div>Assessor: {submission.Assessor.name}</div>
      </div>
    </div>
    <div class="self-start leading-none shrink-0 text-sm">
      {submission.entrustability ? submission.entrustability + " / 5" : "-"}
    </div>
  </div>
{/each}
