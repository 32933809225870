<script>
  import Header from "../components/Header.svelte";
  import { fade } from "svelte/transition";
</script>

<div class="border-b border-muted">
  <div
    class="mx-auto w-[720px] max-w-full px-4"
    in:fade={{ delay: 0, duration: 100 }}
  >
    <Header />
    <section class="py-20 px-2">
      <h1 class="font-bold text-3xl mb-1">Welcome to skillsbook</h1>
      <p class="text-muted-foreground mb-12">
        Login with SUN single sign-on to continue
      </p>

      <a
        href={import.meta.env.VITE_API_HOSTNAME + "/auth"}
        class="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring touch-manipulation h-10 px-8 bg-primary text-primary-foreground shadow hover:bg-primary/90"
        >Login</a
      >
    </section>
  </div>
</div>
