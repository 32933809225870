<script>
  import { state } from "../lib/State";

  let filterText = "";

  export let options = [];
  export let assessorSuid = "";

  $: filteredOptions = (() => {
    let currentSuid = $state.auth.name;
    let assessorSelected = options.some((e) => e.name === filterText);

    return options.filter(
      (option) =>
        (option.name.toLowerCase().includes(filterText.toLowerCase()) ||
          assessorSelected) &&
        option.name != currentSuid
    );
  })();

  const selectOption = (option, target) => {
    filterText = option.name;
    assessorSuid = option.suid;
    target.blur();
  };
</script>

<div class="relative group w-full">
  <input
    name="facilitator"
    required
    type="text"
    bind:value={filterText}
    placeholder="Assessed by"
    class=" placeholder:text-foreground h-11 transition-colors appearance-none px-4 py-3 outline-none group-focus-within:border-foreground border rounded-md leading-none block w-full {options.some(
      (e) => e.name === filterText
    )
      ? 'border-green-500 bg-green-500/10'
      : 'border-input bg-inherit'}"
  />
  <ul
    class="p-1 group-focus-within:block focus:block absolute hidden w-full top-full bg-background border border-muted rounded-md shadow overflow-x-hidden overflow-y-auto max-h-48 no-scrollbar"
  >
    {#each filteredOptions as option}
      <button
        tabindex="0"
        type="button"
        on:click={selectOption(option, this)}
        class="text-foreground block px-3 py-2 w-full text-left hover:bg-muted rounded-sm transition-colors"
        >{option.name}</button
      >
    {/each}
  </ul>
</div>
