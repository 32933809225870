<script>
  import Logo from "../assets/logo.svg";
  import { state, logout } from "../lib/State.js";
</script>

<header class="flex justify-between py-4">
  <!--   <div class="min-w-24">
    <a
      href={import.meta.env.VITE_API_HOSTNAME + "/help"}
      target="_blank"
      class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none touch-manipulation disabled:opacity-50 text-primary underline-offset-4 hover:underline h-9 px-3 py-2"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="mr-2"
        ><circle cx="12" cy="12" r="10" /><path d="M12 16v-4" /><path
          d="M12 8h.01"
        /></svg
      >Help
    </a>
  </div> -->
  <div class="flex items-center gap-3">
    <img src={Logo} class="h-9 w-[1.8rem] py-0.5" alt="Logo" />
    <span class="font-medium">Skillsbook</span>
  </div>

  <div class="min-w-24 text-right">
    {#if !$state?.auth.loggedin}
      <a
        href={import.meta.env.VITE_API_HOSTNAME + "/auth"}
        class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none touch-manipulation disabled:opacity-50 text-primary underline-offset-4 hover:underline h-9 px-3 py-2"
        >Login
      </a>
    {:else}<button
        on:click={logout}
        class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none touch-manipulation disabled:opacity-50 text-primary underline-offset-4 hover:underline h-9 px-3 py-2"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="mr-2"
          ><rect width="18" height="11" x="3" y="11" rx="2" ry="2" /><path
            d="M7 11V7a5 5 0 0 1 10 0v4"
          /></svg
        >Logout
      </button>{/if}
  </div>
</header>
