<script>
  import AddSkill from "../components/AddSkill.svelte";
  import AssessPeers from "../components/AssessPeers.svelte";
  import Header from "../components/Header.svelte";
  import MySkills from "../components/MySkills.svelte";

  import { state, updateState } from "../lib/State";

  import { fade } from "svelte/transition";

  let assessPage = false;

  let progressDysfunction = $state.submissions.filter(
    (i) =>
      i.Assessor.facilitator & (i.Skill.semester == 4) && i.entrustability >= 3
  ).length;

  let progressHW = $state.submissions.filter(
    (i) =>
      i.Assessor.facilitator & (i.Skill.semester == 3) && i.entrustability >= 3
  ).length;

  let progressPeer = $state.submissions.filter(
    (i) => !i.Assessor.facilitator && i.entrustability >= 3
  ).length;
</script>

<div
  class="mx-auto w-[720px] max-w-full px-4"
  in:fade={{ delay: 0, duration: 200 }}
>
  <Header />
  <section
    class="h-9 rounded-lg bg-muted p-1 text-muted-foreground mb-2 grid w-full grid-cols-2"
  >
    <button
      data-state={!assessPage ? "active" : ""}
      class="appearance-none touch-manipulation inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
      on:click={() => {
        updateState();
        assessPage = false;
      }}>My Skills</button
    >
    <button
      data-state={assessPage ? "active" : ""}
      class="appearance-none touch-manipulation inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
      on:click={() => {
        updateState();
        assessPage = true;
      }}>Assess Peers</button
    >
  </section>

  {#if !assessPage}
    <section
      class="rounded-xl border border-muted bg-card text-card-foreground shadow-sm mb-4"
    >
      <div class="flex flex-col space-y-1.5 p-5">
        <h1 class="leading-none font-medium tracking-tight text-lg mb-1">
          Welcome, {$state.auth.name}
        </h1>
        <p class="text-sm text-muted-foreground">
          Log the required skills before [date] 2024. The progress bars aren't
          working yet...
        </p>
      </div>
      <div class="p-5 pt-0">
        <div class="mb-4">
          <div class="mb-2 flex justify-between text-sm">
            <div>Facilitator assessed: Dysfunction</div>
            {progressDysfunction}/3
          </div>
          <progress
            value={progressDysfunction}
            max={3}
            class="
          h-2 w-full
          block
          [&::-webkit-progress-bar]:rounded-full
          [&::-webkit-progress-bar]:overflow-hidden
          [&::-webkit-progress-bar]:bg-muted
          [&::-webkit-progress-value]:bg-red-500
          [&::-moz-progress-bar]:bg-red-500"
          />
        </div>
        <div class="mb-4">
          <div class="mb-2 flex justify-between text-sm">
            <div>Facilitator assessed: Health & Wellness</div>
            {progressHW}/3
          </div>
          <progress
            value={progressHW}
            max={3}
            class="
          h-2 w-full
          block
          [&::-webkit-progress-bar]:rounded-full
          [&::-webkit-progress-bar]:overflow-hidden
          [&::-webkit-progress-bar]:bg-muted
          [&::-webkit-progress-value]:bg-yellow-500
          [&::-moz-progress-bar]:bg-yellow-500"
          />
        </div>
        <div>
          <div class="mb-2 flex justify-between text-sm">
            <div>Peer assessed</div>
            {progressPeer}/6
          </div>
          <progress
            value={progressPeer}
            max={6}
            class="
          h-2 w-full
          block
          [&::-webkit-progress-bar]:rounded-full
          [&::-webkit-progress-bar]:overflow-hidden
          [&::-webkit-progress-bar]:bg-muted
          [&::-webkit-progress-value]:bg-emerald-500
          [&::-moz-progress-bar]:bg-emerald-500"
          />
        </div>
      </div>
    </section>
    <div class="flex gap-4 justify-between">
      <select
        disabled
        name="site"
        class="appearance-none text-sm px-3 py-2 outline-none bg-inherit border transition-colors border-input rounded-md leading-none block mb-3 h-9 w-24"
        required
      >
        <option value="1">2023</option>
        <option value="2" selected>2024</option>
      </select>
      <AddSkill>
        <button
          class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none touch-manipulation disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 px-3 py-2"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="mr-2"><path d="M5 12h14" /><path d="M12 5v14" /></svg
          >Log Skill</button
        >
      </AddSkill>
    </div>

    <MySkills />
  {:else}
    <AssessPeers />
  {/if}
</div>
